import React from 'react';
import { Flex, Image, Text, Heading, Link } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import logo from '../../Resources/Images/Logos/backstage_logo.svg';

const BackStageNavbar: React.FC = () => {
  return (
    <Flex as='header' position='sticky' top='0' w='100%' h='64px' p='0 30px'
      justify='space-between' alignItems='center' zIndex='999'
      borderBottom='1px solid' borderColor='grey.light' backgroundColor='#fff'
    >
      <Heading as='h1' variant='lg_normal'>
        <NavLink exact to={'/bs/statistic'}>
          <Flex align='center'>
            <Image h='40px' cursor='pointer'
              alt='教育部電子證書驗證系統後台管理系統'
              src={logo}
            />
            <Text display='none'>教育部電子證書驗證系統後台管理系統</Text>
            {(window as any).env.BETA && <Text>[測試版]</Text>}
          </Flex>
        </NavLink>
      </Heading>
      <Flex as='nav'>
        <NavLink exact to={'/bs/statistic'}>
          <Text mr='32px' textStyle='normal_md' textAlign='center'
            _hover={{fontWeight: 600}}
          >
            統計數據
          </Text>
        </NavLink>
        <NavLink exact to={'/bs/bulletin'}>
          <Text mr='32px' textStyle='normal_md' textAlign='center'
            _hover={{fontWeight: 600}}
          >
            計畫公告
          </Text>
        </NavLink>
        <NavLink exact to={'/bs/document'}>
          <Text mr='32px' textStyle='normal_md' textAlign='center'
            _hover={{fontWeight: 600}}
          >
            文件下載
          </Text>
        </NavLink>
        <Link href={(window as any).env.MAIN_HOST + '/3ff4d155/'}>
          <Text mr='32px' textStyle='normal_md' textAlign='center'
            _hover={{fontWeight: 600}}
          >
            登出
          </Text>
        </Link>
      </Flex>
    </Flex>
  )
}

export default BackStageNavbar;