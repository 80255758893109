import React, { useState, useEffect } from 'react';
import { Flex, Stack, Image, Text, Button, FormLabel, Input,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
  useDisclosure, List, ListItem } from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import Spinner from 'react-spinners/MoonLoader';
import { useTranslation } from 'react-i18next';

import { readfile } from '../../Utils/common';
import { getToken, submitFile } from '../../Utils/frontStageApi';
import useMedia from '../../Utils/useMedia';
import fileIcon from '../../Resources/Images/Banners/file_banner.png';
import pdfIcon from '../../Resources/Images/Banners/pdf_banner.png';
import uploadIcon from '../../Resources/Images/Icons/upload_icon.svg';
import successIcon from '../../Resources/Images/Icons/success_icon.svg';
import warningIcon from '../../Resources/Images/Icons/warning_icon.svg';
import errorIcon from '../../Resources/Images/Icons/error_icon.svg';

const MAIN_HOST = (window as any).env.MAIN_HOST;

enum Estatus {
  upload = 0,
  status,
  result
};

enum EuploadStatus {
  normal = 0,
  fail,
  overSize
};

enum Eresult {
  null = -1,
  success = 0,
  expired = 1,
  failure = 2,
  captchaError = 3,
  JWTExpired = 4
};

interface Metadata {
  chinese_name: string,
  student_id: string,
  graduate_univ: string,
  graduate_colg: string,
  graduate_dept: string,
  AATL: string
}

const emptyMetadata = {
  chinese_name: '',
  student_id: '',
  graduate_univ: '',
  graduate_colg: '',
  graduate_dept: '',
  AATL: ''
}

const UploadSection: React.FC<{
  status: Estatus;
  setStatus: (status: Estatus) => void;
}> = ({ status, setStatus }) => {
  const { t, i18n } = useTranslation();
  const [token, setToken] = useState<string>('');
  const [captchaID, setCaptchaID] = useState<string>('');
  const [captchaChallenge, setCaptchaChallenge] = useState<string>('');
  //Upload box status
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadFile, setUploadFile] = useState<File>();
  const [wrongFormat, setWrongFormat] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<EuploadStatus>(EuploadStatus.normal);
  const [result, setResult] = useState<Eresult>(Eresult.null); // success | expired | failure | captchaError
  const [metadata, setMetadata] = useState<Metadata>(emptyMetadata);
  //Verification result text and image
  const resultImage = [successIcon, warningIcon, errorIcon, errorIcon, errorIcon];
  const resultTitle = [t('upload.verifySuccess'), t('upload.verifyWarning'), t('upload.verifyFailed'), t('upload.verifyFailed'), t('upload.verifyFailed')];
  const resultColor = ['#1D7228', '#CD7304', '#C4040B', '#C4040B'];
  const resultText = [t('upload.successInfo'), t('upload.waringInfo'), '', '', ''];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isMobile, isDesktop, isTablet } = useMedia();

  useEffect(() => {
    getToken(setToken);
  }, []);

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (status !== Estatus.upload) return;
    setLoading(true);
    setUploadStatus(EuploadStatus.normal);
    const file = e.dataTransfer.files[0];
    readfile(file, setWrongFormat, setStatus, setUploadFile, setCaptchaID);
    setCaptchaChallenge('');
    setLoading(false);
  }

  const btnHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setUploadStatus(EuploadStatus.normal);
    const file = e.target.files[0];
    readfile(file, setWrongFormat, setStatus, setUploadFile, setCaptchaID);
    setCaptchaChallenge('');
    setLoading(false);
  };

  const handleChallenge = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let newValue = (e !== null ? e.target.value : '');
    setCaptchaChallenge(newValue);
    //console.log('now challenge=' + newValue);
  }

  const resultHintModal = <Modal isOpen={isOpen} onClose={onClose} size='xl' isCentered>
    <ModalOverlay />
    <ModalContent borderRadius='12px'
      boxShadow='0px 12px 24px rgba(37, 42, 49, 0.24), 0px 8px 60px rgba(37, 42, 49, 0.32)'
    >
      <ModalHeader display='flex' flexDirection='column' alignItems='start'>
        <Image src={errorIcon} w='56px' mb='8px' alt='error'/>
        <Text textStyle='bold_lg'>{t('modal.title')}</Text>
      </ModalHeader>
      <ModalBody>
        <Text textStyle='normal_md' color='grey.700'>{t('modal.reason1')}</Text>
        <Text textStyle='normal_md' color='grey.700'>{t('modal.reason2')}</Text>
      </ModalBody>
      <ModalFooter display='flex' justifyContent='end'>
        <StyleBtn onClick={onClose} >
          {t('modal.confirm')}
        </StyleBtn>
      </ModalFooter>
    </ModalContent>
  </Modal>

  const captchaHintModal = <Modal isOpen={isOpen} onClose={onClose} size='xl' isCentered>
    <ModalOverlay />
    <ModalContent borderRadius='12px'
      boxShadow='0px 12px 24px rgba(37, 42, 49, 0.24), 0px 8px 60px rgba(37, 42, 49, 0.32)'
    >
      <ModalHeader display='flex' flexDirection='column' alignItems='start'>
        <Image src={errorIcon} w='56px' mb='8px' alt='error'/>
        <Text textStyle='bold_lg'>{t('modal.captchaHintTitle')}</Text>
      </ModalHeader>
      <ModalBody>
        <Text textStyle='normal_md' color='grey.700'>{t('modal.captchaHintText')}</Text>
      </ModalBody>
      <ModalFooter display='flex' justifyContent='end'>
        <StyleBtn onClick={onClose} >
          {t('modal.confirm')}
        </StyleBtn>
      </ModalFooter>
    </ModalContent>
  </Modal>

  const metadataBlock = ()  => {
    let err_idx = metadata.chinese_name.indexOf('error:')
    if (err_idx !== -1)
      return(
        <Flex bg='#DDDDDD' p='10px' borderRadius='12px' marginTop='2px' marginBottom='10px'>
          <List spacing={0}>
            <ListItem><WarningIcon /> {metadata.chinese_name.substring(err_idx + 6)}</ListItem>
          </List>
        </Flex>
      )
    else {
      if (i18n.language === 'zh')
        return (
          <Flex bg='#DDDDDD' p='10px' borderRadius='12px' marginTop='2px' marginBottom='10px'>
            <List spacing={0}>
              {metadata.chinese_name !== '' && <ListItem><CheckCircleIcon /> 學生姓名：{metadata.chinese_name}</ListItem>}
              {metadata.student_id !== '' && <ListItem><CheckCircleIcon /> 學生學號：{metadata.student_id}</ListItem>}
              {metadata.graduate_univ !== '' && <ListItem><CheckCircleIcon /> 畢業學校：{metadata.graduate_univ}</ListItem>}
              {metadata.graduate_colg !== '' && <ListItem><CheckCircleIcon /> 畢業學院：{metadata.graduate_colg}</ListItem>}
              {metadata.graduate_dept !== '' && <ListItem><CheckCircleIcon /> 畢業系所：{metadata.graduate_dept}</ListItem>}
              {metadata.AATL !== '' && <ListItem><CheckCircleIcon /> 數位簽章：{metadata.AATL}</ListItem>}
            </List>
          </Flex>
        )
      else
        return (
          <Flex bg='#DDDDDD' p='10px' borderRadius='12px' marginTop='2px' marginBottom='10px'>
            <List spacing={0}>
              {metadata.chinese_name !== '' && <ListItem><CheckCircleIcon /> STUDENT NAME：{metadata.chinese_name}</ListItem>}
              {metadata.student_id !== '' && <ListItem><CheckCircleIcon /> STUDENT ID：{metadata.student_id}</ListItem>}
              {metadata.graduate_univ !== '' && <ListItem><CheckCircleIcon /> GRAD. SCHOOL：{metadata.graduate_univ}</ListItem>}
              {metadata.graduate_colg !== '' && <ListItem><CheckCircleIcon /> GRAD. COLLEGE：{metadata.graduate_colg}</ListItem>}
              {metadata.graduate_dept !== '' && <ListItem><CheckCircleIcon /> GRAD. DEPARTMENT：{metadata.graduate_dept}</ListItem>}
              {metadata.AATL !== '' && <ListItem><CheckCircleIcon /> DIGITAL SIGNATURE：{metadata.AATL}</ListItem>}
            </List>
          </Flex>
        )
    }
  }

  const metadataExists = () => {
    if (metadata.chinese_name  !== '') return true;
    if (metadata.student_id    !== '') return true;
    if (metadata.graduate_univ !== '') return true;
    if (metadata.graduate_colg !== '') return true;
    if (metadata.graduate_dept !== '') return true;
    if (metadata.AATL          !== '') return true;
    return false;
  }

  return (
    <Flex w='100%' p='16px' my='4px'
      direction='column' justify='center' align='center' bg='#ECF3F8'
      borderRadius='12px' boxShadow='0px 8px 24px rgba(174, 202, 204, 0.15)'
      onDrop={onDropHandler}
      onDragOver={(e) => { e.preventDefault(); }}
      onDragEnter={(e) => { e.preventDefault(); }}
    >
    {status === Estatus.result && !loading && result === Eresult.success && metadataExists() && metadataBlock()}
      {/* Loading circle box */}
      {
        loading &&
        <Flex h='300px' direction='column' justify='center' align='center'>
          <Spinner color='primary.main' />
          <Text mt='24px' color='text.colored'>{t('upload.verifying')}</Text>
        </Flex>
      }
      {/* Upload PDF status */}
      {status === Estatus.upload && !loading &&
        <>
          {
            wrongFormat ? 
            <>
              <Image maxH='175px' h='50%' src={pdfIcon} alt='pdf icon'/>
              <Flex mt='8px' align='center' direction='column'>
                <Flex>
                  <Text display='inline-block' textStyle='normal_md'>{t('upload.onlyPDF')[0]}</Text>
                </Flex>
                <Flex align='center' mt='32px'>
                  <Text>{t('upload.onlyPDF')[1]}&emsp;</Text>
                  <StyleBtn>
                    {t('upload.uploadBtn')}
                    <Input type='file' accept='.pdf'
                      onChange={btnHandler} display='none'
                    />
                  </StyleBtn>
                </Flex>
              </Flex>
            </>
             : 
            <>
              <Image maxH='451px' h='50%' src={fileIcon} alt='file icon'/>
              <Flex mt='38px' align='center' direction='column'>
                <Flex direction='row'>
                  <Text textStyle={!isDesktop ? 'normal_xs' : 'normal_md'}>
                    {t('upload.drag')[0]}
                    {i18n.language === 'en' && <span>&ensp;</span>}<b>{t('upload.drag')[1]}</b>
                    {i18n.language === 'en' && <span>&ensp;</span>}{t('upload.drag')[2]}
                    {i18n.language === 'en' && <span>&ensp;</span>}<b>{t('upload.drag')[3]}</b>
                    {i18n.language === 'en' && <span>&ensp;</span>}{t('upload.drag')[4]}
                  </Text>
                </Flex>
                <Flex align='center' mt='32px'>
                  <Text>{t('upload.drag')[5]}&emsp;</Text>
                  <StyleBtn>
                    {t('upload.uploadBtn')}
                    <Input type='file' accept='.pdf'
                      onChange={btnHandler} display='none'
                    />
                  </StyleBtn>
                </Flex>
              </Flex>
            </>
          }
        </>
      }
      {/* Check uploading file status*/}
      {status === Estatus.status && !loading &&
        <>
          {uploadStatus === EuploadStatus.normal ?
            <>
              {captchaHintModal}
              <Image maxH='168px' h='50%' src={uploadIcon} />
              {
                uploadFile &&
                <Text mt='11px' textStyle='normal_md'>{uploadFile.name}</Text>
              }
              {
                captchaID !== '' &&
                <Flex w='80%' maxW='320px' mt='32px' justify='center' direction='column' gap='32px'>
                  <Image src={MAIN_HOST + '/captcha/' + captchaID + '.jpg'} />
                  <Input type='text' name='challenge' placeholder={t('upload.captchaHint')} onChange={handleChallenge} maxLength={5} />
                </Flex>
              }
              <Flex w='80%' mt='32px' justify='center' gap='32px'>
                <Button variant='outlinedSecondary' textStyle='normal_sm'
                  p='8px 12px' mr='4px' borderRadius='4px' border='solid 1px #A8B8C2'
                  onClick={() => setStatus(Estatus.upload)}
                >
                  {t('upload.tryAgain')}
                </Button>
                <Button variant='primary' textStyle='normal_sm' 
                  p='8px 12px' ml='4px' borderRadius='4px'
                  onClick={() => {
                    if (captchaID !== '' && captchaChallenge.length !== 5) {
                      //console.log('about to open captchaHintModal');
                      onOpen();
                    }
                    else {
                      setLoading(true)
                      submitFile(uploadFile, token, captchaID, captchaChallenge, setLoading, setStatus, setResult, setMetadata, setUploadStatus)
                    }
                  }}
                >
                  {t('upload.verify')}
                </Button>
              </Flex>
            </>
            :
            <>
              <Image maxH='108px' h='50%' src={resultImage[2]} />
              <Flex mt='16px' align='center' direction='column'>
                <Text color='critical.400' textStyle='bold_lg'>{t('upload.uploadFailed')}</Text>
                {uploadStatus === EuploadStatus.overSize &&
                  <Text mt='16px' textStyle='normal_md'>{t('upload.overSize')}</Text>
                }
                <Flex align='center' mt='32px'>
                  <Text>{t('upload.onlyPDF')[1]}&emsp;</Text>
                  <StyleBtn>
                    {t('upload.uploadBtn')}
                    <Input type='file' accept='.pdf'
                      onChange={btnHandler} display='none'
                    />
                  </StyleBtn>
                </Flex>
              </Flex>
            </>
          }
        </>
      }
      {/* Verification result */}
      {status === Estatus.result && !loading &&
        <>
          <Image maxH='108px' h='40%' src={resultImage[result]} />
          <Text my='16px' color={resultColor[result]} textStyle='bold_lg'>
            {resultTitle[result]}
          </Text>
          <Stack alignItems='center'>
            {resultText[result].split('\n').map(text =>
              <Text textStyle='normal_md'>{text}</Text>
            )}
            {result === Eresult.failure &&
              <Flex>
                <Text>
                  <Text display='inline-block' mr='8px' textStyle='normal_md'>
                    {t('upload.failedInfo')[0]}
                  </Text>
                  <Text display='inline-block' textStyle='normal_md'
                    color='#005AA3' textDecoration='underline' onClick={onOpen}>
                    {t('upload.failedInfo')[1]}
                  </Text>
                </Text>
                {resultHintModal}
              </Flex>
            }
            {result === Eresult.captchaError &&
              <Flex>
                <Text display='inline-block' mr='8px' textStyle='normal_md'>
                  {t('upload.captchaError')}
                </Text>
              </Flex>
            }
            {result === Eresult.JWTExpired &&
              <Flex>
                <Text display='inline-block' mr='8px' textStyle='normal_md'>
                  {t('upload.JWTExpired')}
                </Text>
              </Flex>
            }
          </Stack>
          {result !== Eresult.JWTExpired &&
            <StyleBtn mt='32px' p='8px 12px' variant='primary'
              borderRadius='4px' onClick={() => setStatus(Estatus.upload)}
            >
              {result === Eresult.captchaError ? t('upload.captchaRetry') : t('upload.verifyAgain')}
            </StyleBtn>
          }
        </>
      }
    </Flex>
  )
}

export default UploadSection;

const StyleBtn = styled(FormLabel)`
  padding: 8px 12px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #35658D;
  cursor: pointer;
  :active {
    background-color: #1D3957;
  }
`
