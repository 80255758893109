import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Text } from '@chakra-ui/react';
import { slide as Menu } from 'react-burger-menu';
import { useTranslation } from 'react-i18next';
import './Burger.css';

export default (props: any) => {
  const { t, i18n } = useTranslation();
  return (
    <Menu right width={'228px'}>
      <NavLink exact to='/about' key={'0'}>
        <Text mr='32px' _hover={{ fontWeight: 600 }}>{t('navbar.about')}</Text>
      </NavLink>
      <br />
      <NavLink exact to='/upload' key={'1'}>
        <Text mr='32px' _hover={{ fontWeight: 600 }}>{t('navbar.verification')}</Text>
      </NavLink>
      <br />
      {i18n.language === 'zh' &&
      <NavLink exact to='/news' key={'2'}>
        <Text mr='32px' _hover={{ fontWeight: 600 }}>{t('navbar.news')}</Text>
      </NavLink>
      }
      {i18n.language === 'zh' &&
      <br />
      }
      <NavLink exact to='/FAQ' key={'3'}>
        <Text mr='32px' _hover={{ fontWeight: 600 }}>{t('navbar.FAQ')}</Text>
      </NavLink>
      <br />
      <NavLink exact to='/school' key={'4'}>
        <Text mr='32px' _hover={{ fontWeight: 600 }}>{t('navbar.issuanceUnits')}</Text>
      </NavLink>
    </Menu>
  );
};