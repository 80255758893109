import React, { useEffect, useState } from 'react';
import { Flex, Image, Text, Heading, Link } from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo from '../../Resources/Images/Logos/logo.svg';
import logo_eng from '../../Resources/Images/Logos/english_logo.svg';
import logo_dev from '../../Resources/Images/Logos/logo_dev.svg';
import logo_eng_dev from '../../Resources/Images/Logos/english_logo_dev.svg';

import Burger from './Burger';
import useMedia from '../../Utils/useMedia';

const Navbar: React.FC = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [isChinese, setIsChinese] = useState<boolean>(i18n.language === 'zh');
  const { isMobile, isDesktop, isTablet } = useMedia();
  const navLinks = [
    { path: '/about', text: t('navbar.about') },
    { path: '/upload', text: t('navbar.verification') },
    { path: '/news', text: t('navbar.news') },
    { path: '/FAQ', text: t('navbar.FAQ') },
    { path: '/school', text: t('navbar.issuanceUnits') },
  ];

  const checkLetterSpacing = () => {
    const elements = Array.from(document.getElementsByTagName('p'));
    elements.forEach((el) => {
      el.style.letterSpacing = isChinese ? '1px' : '0.5px';
    })
    const anchors = Array.from(document.getElementsByTagName('a'));
    anchors.forEach((el) => {
      el.style.letterSpacing = isChinese ? '1px' : '0.5px';
    })
    const labels = Array.from(document.getElementsByTagName('label'));
    labels.forEach((el) => {
      el.style.letterSpacing = isChinese ? '1px' : '0.5px';
    })
    const buttons = Array.from(document.getElementsByTagName('button'));
    buttons.forEach((el) => {
      el.style.letterSpacing = isChinese ? '1px' : '0.5px';
    })
  };
  const toggleLang = () => {
    if (isChinese) {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('zh');
    }
    setIsChinese(!isChinese);
  };

  useEffect(() => {
    checkLetterSpacing();
  }, [location.pathname, isChinese]);

  return (
    <>
    <Burger />
    <Flex as='header' position='sticky' top='0' w='100%' h='64px' p='0 10px'
      justify='space-between' alignItems='center' zIndex='999'
      borderBottom='1px solid' borderColor='grey.light' backgroundColor='#fff'
    >
      <Heading as='h1' variant='lg_normal'>
        <NavLink exact to={'/upload'}>
          <Flex align='center'>
            <Image h='36px' cursor='pointer'
              alt='教育部電子學位證書驗證平台上傳頁面'
              src={i18n.language === 'zh' ?
                ((window as any).env.BETA ? logo_dev : logo) :
                ((window as any).env.BETA ? logo_eng_dev : logo_eng)}
            />
            <Text display='none'>教育部電子學位證書驗證平台</Text>
          </Flex>
        </NavLink>
      </Heading>
      <Flex as='nav' textStyle='normal_md' textAlign='center' alignItems={'center'}>
        <a href="#U" title="右上方功能區塊" id="AU" accessKey="U" style={{marginRight:'16px'}}>:::</a>
        {isDesktop && navLinks.filter(link => link.text !== '').map((link, index) => (
          <NavLink exact to={link.path} key={index}>
            <Text mr='32px' _hover={{ fontWeight: 600 }}>{link.text}</Text>
          </NavLink>
        ))}
        {isChinese
          ? <Link href='#' height={'fit-content'} textStyle='normal_md' onClick={toggleLang}>EN</Link>
          : <Link href='#' height={'fit-content'} textStyle='normal_md' onClick={toggleLang}>中文</Link>
        }
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Flex>
    </Flex>
    </>
  )
}

export default Navbar;