import { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, TableContainer, TableCaption,
  Stack, Text, Flex, Center } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface Props {
  colNames: string[],
  listItems: JSX.Element[],
  noDataText?: string,
  unitText?: string
};

const PAGE_SIZE: number = 10;

const PaginationTable = ({ 
  colNames, 
  listItems, 
  noDataText = '尚未有任何資料', 
  unitText = '筆'
}: Props) => {
  const [currentIdx, setCurrentIdx] = useState<number>(0);
  const [displayRange, setDisplayRange] = useState<{ begin: number, end: number }>(
    { begin: 0, end: 3 }
  );
  const numOfPage = Math.ceil(listItems.length / PAGE_SIZE);
  const calculateTdWidth = (index: number) => {
    if(index === 0)
      return '15%';
    else if(index === 2)
      return '10%';
    else
      return ''
  };
  const VerticalLine = () => <Flex borderRight='1px solid #35658D' />;

  //Control page index functions
  const handleNext = () => {
    if (currentIdx + 1 >= numOfPage) return;
    setCurrentIdx(currentIdx + 1);
  };
  const handleLast = () => {
    if (currentIdx - 1 < 0) return;
    setCurrentIdx(currentIdx - 1);
  };

  // Update the display range when the current page index changes
  useEffect(() => {
    if (currentIdx < 2)
      setDisplayRange({ begin: 0, end: 3 });
    else if (currentIdx > numOfPage - 3)
      setDisplayRange({ begin: numOfPage - 3, end: numOfPage });
    else
      setDisplayRange({ begin: currentIdx - 1, end: currentIdx + 2 });
  }, [currentIdx, numOfPage]);
  useEffect(() => {
    if (currentIdx >= numOfPage)
      setCurrentIdx(0);
  }, [listItems, currentIdx, numOfPage]);

  return (
    <Stack spacing='24px'>
      {/* Filtered Certificate Table */}
      <TableContainer>
        <Table bg='white'>
          {listItems.length === 0 && <TableCaption my='10px' p='0'>{noDataText}</TableCaption>}
          <Thead>
            <Tr>
              {colNames.map((name: string, index) =>
                <Th key={index} width={calculateTdWidth(index)} p='10px 12px' textStyle='sm_bold' borderColor='#A8B8C2'>{name}</Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {listItems.slice(PAGE_SIZE * currentIdx, PAGE_SIZE * currentIdx + PAGE_SIZE)}
          </Tbody>
        </Table>
      </TableContainer>
      {/* Index Component */}
      <Flex align='center' alignSelf='end' color='primary.main'>
        <Text mr='16px' color='grey.dark' textStyle='normal_xs'>
          {`
          ${currentIdx * 10 + 1} - 
          ${(currentIdx + 1) * 10 > listItems.length ? listItems.length : (currentIdx + 1) * 10} / 
          ${listItems.length}
          `}
          {unitText}
        </Text>
        <Stack direction='row' spacing='0'
          border='1px solid #35658D' borderRadius='4px'
        >
          <ChevronLeftIcon w={8} h={8}
            onClick={handleLast} cursor='pointer'
          />
          {currentIdx > 1 && numOfPage > 3 && <>
            <VerticalLine />
            <Center w='32px'>...</Center>
          </>}
          <VerticalLine />
          {Array.from(Array(numOfPage).keys())
            .slice(displayRange.begin, displayRange.end)
            .map(num => 
            <Flex key={num}>
              <Center
                w='32px'
                color={currentIdx === num ? 'white' : ''}
                bgColor={currentIdx === num ? 'primary.main' : ''}
                onClick={() => setCurrentIdx(num)}
                cursor='pointer'
              >
                <Text textStyle='normal_sm'>{num + 1}</Text>
              </Center>
              <VerticalLine />
            </Flex>)}
          {currentIdx < numOfPage - 2 && numOfPage > 3 && <>
            <Center w='32px'>...</Center>
            <VerticalLine />
          </>}
          <ChevronRightIcon w={8} h={8}
            onClick={handleNext} cursor='pointer'
          />
        </Stack>
      </Flex>
    </Stack>
  );
};

export default PaginationTable;