import React from 'react';
import { Flex, Image, Text, Divider, Heading } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';

import chBanner from '../Resources/Images/Banners/ch_about_banner.png';
import enBanner from '../Resources/Images/Banners/en_about_banner.png';
import CenterGuideBrick from '../Components/Common/CenterGuideBrick';

import useMedia from '../Utils/useMedia';

const AboutPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { isMobile, isDesktop, isTablet } = useMedia();
  
  return (
    <Flex w='100%' minH='84vh' direction='column' align='center'>
      <Image w='100%' maxH='301px' objectFit='cover' src={i18n.language === 'zh' ? chBanner : enBanner} alt='教育部標章'/>
      <Flex w='85%' pt='31px' pb={isMobile ? '70px' : (isDesktop ? '10px' : '60px')} direction='column'>
        <CenterGuideBrick/>
        <Heading as='h2' variant='lg_normal'>
          {t('navbar.about')}
        </Heading>
        <Text mt='32px' textStyle='normal_md'>{t('about.text1')}</Text>
        <Text textStyle='semibold_md' mt='32px' mb='8px'>{t('about.certFeatureTitle')}</Text>
        {t('about.certFeature')
          .split('\n')
          .map(text => <Text key={text}>{text}</Text>)
        }
        <Divider my='32px' color='#E8EDF1' />
        <Text textStyle='semibold_md'>{t('about.contact')}</Text>
        {t('about.contactPerson').split('\n').map(text => 
          <Text key={text} textStyle='normal_sm' color='grey.700'>{text}</Text>
        )}
      </Flex>
    </Flex>
  )
};

export default AboutPage;
