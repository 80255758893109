import React from 'react'
import { Flex, Text, Image } from '@chakra-ui/react'
import theme from '../../Styles/theme'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { schools } from '../../Resources/partnership/schools'

const SchoolTab: React.FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Flex w='100%' direction='row' flexWrap='wrap' justify='space-between'>
        {schools.map(school =>
          <Flex style={cardStyle} key={school.english_name}>
            <Image h='100%' maxW='56px' mr='16px' objectFit='contain' src={'images/' + school.logo} alt={i18n.language.includes('zh') ? school.chinese_name : school.english_name}/>
            <SchoolLink href={school.link} target='_blank'>{ i18n.language.includes('zh') ? school.chinese_name : school.english_name }</SchoolLink>
          </Flex>
        )}
      </Flex>
      <Text mt='35px' mb='35px' textAlign='center' color='rgba(0, 20, 21, 0.7)'>{t('school.keepAdding')}</Text>
    </>
  )
}

export default SchoolTab

const cardStyle = {
  width: '45%',
  minWidth: '320px',
  height: '88px',
  padding: '16px 10px',
  margin: '15px 0px',
  alignItems: 'center',
  boxShadow: '4px 8px 10px 0 rgba(194, 211, 220, 0.15), -4px -2px 6px 0 rgba(194, 211, 220, 0.25)',
  borderRadius: '10px'
}

const SchoolLink = styled.a`
  :hover{
    color: ${theme.colors.selected};
    cursor: alias;
  }
`
